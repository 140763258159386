import axios from "axios";
import React from "react";

// pegar a url atual
const url = window.location.href

// pegar a variavel de ambiente da url que vem nessa estrutura https://sandbox.chancelafacil.com.br/
// a variavel de ambiente pode ser live, sandbox, localhost:3000

const urlEnv = url.split(".")[0].split("//")[1]

// se a urlEnv for igual a sandbox, a variavel backendUrl vai ser a url de sandbox "https://api.sandbox.chancelafacil.com.br/",
// se for localhost "http://localhost:8000/"
// se for live é a url de live: "https://api.live.chancelafacil.com.br/"

// função de definição da variavel backendUrl

const backendUrl = () => {
    let urlBackend = null
    if (urlEnv === 'sandbox') {
        urlBackend = "https://api.sandbox.chancelafacil.com.br/"
    } else if (urlEnv === 'live') {
        urlBackend =  "https://api.live.chancelafacil.com.br/"
    } else {
        urlBackend =  "http://localhost:8000/"
    }

    return urlBackend
}

const apiFetch = axios.create({
    baseURL: backendUrl(), // "https://api.sandbox.chancelafacil.com.br/", //  "http://localhost:8000/", //   "https://sandbox.api.signature.mads4.app/", // 
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
    }
})

// apiFetch.defaults.headers.common.Authorization = '';

export default apiFetch