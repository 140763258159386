import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import ProtectedRoutes from './protectedRoutes'

import { Account } from './account';
import { Entity } from './entity';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <Account>
        <Entity>
          <BrowserRouter>
            <ThemeProvider>
                <ScrollToTop />
                <StyledChart />
                <ProtectedRoutes />
            </ThemeProvider>
          </BrowserRouter>
        </Entity>
      </Account>
    </HelmetProvider>
  );
}
