import React, {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  TextField,
  Stack,
  Button,
  Container,
} from '@mui/material';

import { AccountContext } from '../account'

import apiFetch from '../axios/config';

// ----------------------------------------------------------------------

export default function CreateEntityForm(props) {
    const navigate = useNavigate();

    const [entityName, setEntityName] = useState("");
    const [entityDocumentNumber, setEntityDocumentNumber] = useState("");
    const [documentNumberError, setDocumentNumberError] = useState(false)

    const { getSession } = useContext(AccountContext)

    const navigateToEntities = () => {
        // 👇️ navigate to /contacts
        navigate('/dashboard/app');
      };

      const handleDocumentNumberChange = (event) => {
        const formattedDocumentNumber = event.target.value.replace(/\D/g, ''); // Remove non-digits
      
        // Ensure length is exactly 11 for valid CPF or 14 for CNPJ
        const isValid = formattedDocumentNumber.length === 11 || formattedDocumentNumber.length === 14;
        setEntityDocumentNumber(formattedDocumentNumber);
        setDocumentNumberError(!isValid);

      };
    

    const createEntity = async () => {

        getSession()
        .then((data) => {
            const payload = {
                "entity_name": entityName,
                "entity_document_number": entityDocumentNumber
              }
        
            try {

              apiFetch.defaults.headers.common.Authorization = `Bearer ${data.idToken.jwtToken}`
              apiFetch.post(
                `/entity`, payload
              ).then((res) => {
                navigateToEntities()
                }
              )
            
            } catch (error) {
              console.log(error);
            }
        })
    
      }
    
    const handleSubmission = () => {
        if (entityName && entityDocumentNumber) {
            createEntity()
        }
    }

    return (
        <>
        <Helmet>
            <title> Nova Organização </title>
        </Helmet>

        <Container>
        <Stack spacing={2} justifyContent="space-between" mb={5}>
            <TextField 
            id="outlined-basic" 
            label="Nome" 
            variant="outlined"
            onChange={e => setEntityName(e.target.value)}/>
        <TextField
            name="documentNumber"
            label="Numero do Documento"
            value={entityDocumentNumber}
            type="tel"
            error={documentNumberError}
            helperText={documentNumberError ? 'Documento inválido' : ''}
            onChange={handleDocumentNumberChange}
            inputProps={{ maxLength: 14 }}
          />
            <Button variant="contained"
            onClick={handleSubmission}>
                Criar Organização
            </Button>
        </Stack>

        </Container>
        </>
    );
}
