import React, {useState, useContext, useCallback} from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
    Typography,
    Box,
    Stack,
    Container,
    Grid
  } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';

// ----------------------------------------------------------------------


export default function DonePage() {

  return (
    <>
    <Helmet>
        <title> Envio Concluido </title>
    </Helmet>

    <Container>
    <Grid container spacing={10} >
        <Grid item xs={12} sm={6} md={10}>
          <Box sx={{ width: '100%' }}>
            <Stack alignItems="center" justifyContent="center" m={12}>
                <DoneAllIcon color="success" sx={{ fontSize: 100 }} />
                <Typography>Envelope enviado para assinatura!</Typography>
            </Stack>
          </Box>
        </Grid>
    </Grid>
    </Container>
    </>
  );
}
