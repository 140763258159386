import { useState, useEffect, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Select,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import DocumentNumberField from '../components/document-number-field';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadNoCheckbox, UserListToolbar } from '../sections/@dashboard/user';

import { EntityContext } from '../entity';
import { AccountContext } from '../account'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'company', label: 'Sobrenome', alignRight: false },
  { id: 'role', label: 'Papel', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.user_given_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const AddUserCard = (props) => {
  return (
    <Stack justifyContent="space-between" mb={5}>
      <Card>
        <Typography sx={{ m: 2 }} >Novo Usuario</Typography>
        <Stack direction="row" alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
          <DocumentNumberField 
            documentNumber={props.newUserDocumentNumber} 
            setDocumentNumber={props.setNewUserDocumentNumber} 
            documentNumberError={props.newUserDocumentNumberError}
            setDocumentNumberError={props.setNewUserDocumentNumberError}
            />
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Papel"
                value={props.roleType}
                onChange={e => props.setRoleType(e.target.value)}
            >
                <MenuItem value={'manager'}>Administrador</MenuItem>
                <MenuItem value={'viewer'}>Observador</MenuItem>
                <MenuItem value={'operator'}>Operador</MenuItem>
            </Select>
          <Button 
          sx={{ m: 2 }} 
          variant="contained" 
          component="label" 
          disabled={props.newUserDocumentNumber === '' || props.newUserDocumentNumberError}
          onClick={_ => props.handleAddUser()}
          startIcon={<Iconify icon="eva:plus-fill" />}>
          Adicionar
          </Button>
        </Stack>
      </Card>
    </Stack>
  )
}

export default function UserPage() {

  const [userList, setUserList] = useState([]);

  const [showAddUser, setShowAddUser] = useState(false);

  const [newUserDocumentNumber, setNewUserDocumentNumber] = useState('');
  const [newUserDocumentNumberError, setNewUserDocumentNumberError] = useState(false);

  const [roleType, setRoleType] = useState('operator');

  const [token, setToken] = useState(null)

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { getEntity, addUserToEntity, RemoveUserFromEntity } = useContext(EntityContext)
  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
        .then((data) => {
            setToken(data.idToken.jwtToken)
        })
    }, [])

  const handleAddUser = () => {

    try {
      addUserToEntity(token, newUserDocumentNumber, roleType)
      .then((res) => {
        setNewUserDocumentNumber('')
          setShowAddUser(false)
          getMembersList()
      })
    } catch (error) {
      console.log(error);
    }
  }

  const getMembersList = useCallback(async () => {
	
    getEntity()
        .then((data) => {
          
          setUserList(data.members)
        })
    
      }, [])

  useEffect(() => {
    getMembersList()
    }, [])

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleToggleAddUser = () => {
    setShowAddUser(!showAddUser)
  }

  const handleDelete = (userKey) => {
    RemoveUserFromEntity(token, userKey)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Usuarios </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleToggleAddUser}>
            Adicionar usuario
          </Button>
        </Stack>

        { showAddUser ? <AddUserCard 
          newUserDocumentNumber={newUserDocumentNumber} 
          setNewUserDocumentNumber={setNewUserDocumentNumber}
          newUserDocumentNumberError={newUserDocumentNumberError}
          setNewUserDocumentNumberError={setNewUserDocumentNumberError}
          roleType={roleType}
          setRoleType={setRoleType}
          handleAddUser={handleAddUser}/> : <></> }

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHeadNoCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const selectedUser = selected.indexOf(row.user_given_name) !== -1;

                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell >
                          <></>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {row.user_given_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{row.user_family_name}</TableCell>

                        <TableCell align="left">{row.role_type}</TableCell>

                        <TableCell align="left">
                          <Label>{row.is_active ? 'Ativo' : 'Inativo'}</Label>
                        </TableCell>

                        <TableCell align="right">

                        { row.role_type !== "manager" ?
                          <IconButton size="large" color="error" onClick={_=> handleDelete(row.user_key)}>
                              <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton> : <></>
                        }

                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

    </>
  );
}
