import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import {Typography, Grid, Stack, Button, Card } from '@mui/material';
import PlanCard from './PlansCard';

// ----------------------------------------------------------------------

PlansList.propTypes = {
    plans: PropTypes.array.isRequired,
    SelectPlan: PropTypes.func.isRequired,
};

const CustomPlanCard = (props) => {

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle1" noWrap>
        Não achou o que procurava?
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Solicite um plano personalizado.
          </Typography>
        </Stack>
          <Button variant="contained" size="large" onClick={_ => props.HandleCustomPlan()}>Falar com Comercial</Button>
      </Stack>
    </Card>
  );
}


export default function PlansList({ plans, SelectPlan, ...other }) {

    const navigate = useNavigate();

    const HandleCustomPlan = () => {
        const whatsappURL = `https://wa.me/5511985441304?text=Ol%C3%A1%20Gostaria%20de%20solicitar%20um%20plano%20personalizado%20em%20chancelafacil.com.br`;
        window.open(whatsappURL, '_blank');  
    };

  return (
    <Grid container spacing={3} {...other}>
      {plans.map((plan) => (
        <Grid key={plan.id} item xs={12} sm={6} md={6}>
          <PlanCard plan={plan} SelectPlan={SelectPlan} />
        </Grid>
      ))}
        <Grid item xs={12} sm={6} md={6}>
          <CustomPlanCard HandleCustomPlan={HandleCustomPlan}/>
        </Grid>
    </Grid>
  );
}