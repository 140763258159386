import React, {useState, useEffect, useCallback} from 'react';
// @mui
import {
  Stack,
  Button,
  Typography,
  TextField
} from '@mui/material';

// components
import Iconify from '../iconify';
import DocumentNumberField from '../document-number-field'

export default function SignerCard(props) {

    return (
        <Stack justifyContent="space-between" mb={5}>
            <Typography sx={{ m: 2 }} >Informaçõe do assinante</Typography>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <TextField sx={{ m: 2 }}
                    id="outlined-basic" 
                    label="Nome do Assinante" 
                    variant="outlined"
                    value={props.signerName}
                    onChange={e => props.setSignerName(e.target.value)}/>
                    <DocumentNumberField 
                    documentNumber={props.signerDocumentNumber} 
                    setDocumentNumber={props.setSignerDocumentNumber} 
                    documentNumberError={props.signerDocumentNumberError}
                    setDocumentNumberError={props.setSignerDocumentNumberError}
                    />
                    <TextField sx={{ m: 2 }}
                    id="outlined-basic" 
                    label="Email do assinante" 
                    variant="outlined"
                    value={props.signerEmail}
                    onChange={e => props.setSignerEmail(e.target.value)}/>
                    </Stack>
                    <Stack direction="row" alignItems="left" justifyContent="flex-end">
                    <Button 
                    sx={{ m: 2 }} 
                    variant="contained" 
                    component="label" 
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={_ => props.handleCreateSigner()}>
                    Adicionar assinante
                    </Button>
                </Stack>
            </Stack>
    );
}
