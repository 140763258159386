import {
	CognitoUserPool
} from 'amazon-cognito-identity-js';


const PoolData = {
    UserPoolId: "us-east-2_AL7IzBpXx",
    ClientId: "315kgvm6hsj3rteepr27drmk80"
}

export default new CognitoUserPool(PoolData)