import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm(props) {

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [documentNumber, setDocumentNumber] = useState("")
  const [documentNumberError, setDocumentNumberError] = useState(false)

  const handleDocumentNumberChange = (event) => {
    const formattedDocumentNumber = event.target.value.replace(/\D/g, ''); // Remove non-digits
  
    // Ensure length is exactly 11 for valid CPF
    const isValid = formattedDocumentNumber.length === 11;
    setDocumentNumber(formattedDocumentNumber);
    setDocumentNumberError(!isValid);
  };

  // Separate validation function (example)
  const validateForm = () => {
    let isValid = true;

    if (documentNumber === '') {
      setDocumentNumberError(true);
      isValid = false;
    } else {
      setDocumentNumberError(false); // Clear previous error
    }

    if (password === '') {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false); // Clear previous error
    }

    return isValid;
  };

  const handleForgotPassword = () => {
    if (documentNumber) {
      // Chama a função de esqueci minha senha e recebendo sucesso chama a função de mudar de tela
      props.tryForgotPassword(documentNumber)

    } else {
      setDocumentNumberError(true)
    }
    
  }

  const handlePasswordChange = (event) => {
    const formattedPassword = event.target.value;
  
    // Clear any previous error on input change
    setPasswordError(false); // Reset error state on change
    
    setPassword(formattedPassword);
  };

  const handleClick = async () => {

    // Validate form fields and display errors if necessary
    const isValid = validateForm(); // Call a separate validation function
    if (!isValid) return; // Early return if validation fails
    
    if (!documentNumberError && !passwordError) {
        props.signUp(documentNumber, password)
    }    
  };

  return (
    <>
      <Stack spacing={3}>
      <TextField
          name="documentNumber"
          label="CPF"
          value={documentNumber}
          type="tel"
          error={documentNumberError}
          helperText={documentNumberError ? 'Documento inválido' : ''}
          onChange={handleDocumentNumberChange}
          inputProps={{ maxLength: 11 }}
        />

        <TextField
          name="password"
          label="Senha"
          error={passwordError}
          value={password}
          onChange={handlePasswordChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={_ => handleForgotPassword()}>
          Esqueceu sua senha?
        </Link>
      </Stack>

      <LoadingButton disabled={documentNumberError || passwordError}
      fullWidth size="large" type="submit" variant="contained" onClick={_ => handleClick()}>
        Login
      </LoadingButton>
    </>
  );
}
