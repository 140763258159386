import React, { useState, useEffect } from 'react';

function useIP() {
  const [ip, setIp] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => setIp(data.ip))
  }, []);

  return ip;
}

export default useIP;