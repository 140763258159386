import { useState, useContext, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';

import { AccountContext } from '../../../account';
import { EntityContext } from '../../../entity';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Perfil',
    icon: 'eva:person-fill',
  },
  {
    label: 'Configurações',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const { logout, getSession } = useContext(AccountContext)

  const { entity } = useContext(EntityContext)

  const navigateToProfile = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/profile');
  };

  const navigateToConfiguration = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/configuration');
  };

  const navigateToDashboard = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/app');
  };

  const navigateToEntities = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/entities');
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {

    switch(event) {
      case 'Home':
          navigateToDashboard()
          setOpen(null);
        break;
      case 'Configurações':
          navigateToConfiguration()
          setOpen(null);
        break;
      case 'Perfil':
          navigateToProfile()
          setOpen(null);
        break;
      default:
        setOpen(null);
    }
  };

  const handleLogout = () =>{
    logout()
    window.location.reload()
  }

  useEffect(() => {
    getSession()
        .then((data) => {
          setUserName(data[2].Value)
          setUserEmail(data[4].Value)
        })
}, [])

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar>{userName === '' ? '?': userName.substring(0, 1)}</Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          { entity ? MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={_ => handleClose(option.label)}>
              {option.label}
            </MenuItem>
          )) :
          <MenuItem onClick={_ => navigateToEntities()} >
            Entidades
          </MenuItem>}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
