import fp from "fingerprintjs2";
import { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack, 
  Typography,
  Grid,
  Container
} from '@mui/material';

import PdfComp from '../components/pdf-reader/index';

import apiFetch from '../axios/config'

import SignatureButton from '../components/signature-button/index'
import SignatureHeader from "../components/signature-header";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

function SignaturePage(props) {

  const [envelope, setEnvelope] = useState(null)
  const [fingerprint, setFingerprint] = useState (null)
  const [signatureValitdation, setSignatureValitdation] = useState (false)

  const { signerKey } = useParams();

  const loadEnvelope = useCallback(async () => {
    try {
      const response = await apiFetch.get(
        `/signature/${signerKey}`
      );
  
      const data = response.data;
  
      

      setEnvelope(data)
      // CheckSignatureValidation(data.signers)

    } catch (error) {
      console.log(error);
    }

  }, [signerKey])

  useEffect(() => {
    loadEnvelope()
  }, [])

  return (
    <StyledRoot>
      <SignatureHeader envelope={envelope} signerKey={signerKey}/>
      <Main>
        <>
          <Helmet>
              <title> Assinar </title>
          </Helmet>

          <Container maxWidth="xl">

          <Grid container spacing={3} justifyContent="space-evenly">
          <Grid xs={12} sm={6} >
            <div style={{
              overflowY:"scroll"
              , height: '450px'
            }}>
              {
              envelope ? 
              // Apresenta na tela o pdf presente na variavel envelope.envelope_url
              <PdfComp pdfFile={envelope.envelope_url} />
              : 
                <Typography variant="h4" gutterBottom>
                  No File found
                </Typography>
                }
            </div>
          </Grid>
        </Grid>

          </Container>
        </> 
      </Main>
    </StyledRoot>
      );
}

export default SignaturePage;
