import { Helmet } from 'react-helmet-async';
import { useState, useContext, useEffect, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import {
  TextField,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Box,
  Divider ,
  Tabs,
  Tab
} from '@mui/material';
// components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../components/iconify';
import Label from '../components/label';
import InvoicesList from '../components/invoices-list';

import { EntityContext } from '../entity';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const AddWebhookCard = (props) => {
  return (
    <Stack justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" justifyCon
        tent="center" useFlexGap flexWrap="wrap">
          <TextField sx={{ m: 2 }}
            id="outlined-basic" 
            label="URL" 
            variant="outlined"
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}/>
          <TextField sx={{ m: 2 }}
            id="outlined-basic" 
            label="Headers" 
            variant="outlined"
            value={props.headers}
            onChange={e => props.setHeaders(e.target.value)}/>
          <Button 
          sx={{ m: 2 }} 
          variant="contained" 
          component="label" 
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={_ => props.handleAddWebhook()}>
          Adicionar
          </Button>
        </Stack>
    </Stack>
  )
}

export default function IntegrationPage() {

  const [value, setValue] = useState(0);
  const [planInfo, setPlanInfo] = useState(null)
  const [checkousList, setCheckoutsList] = useState([])

  const { entity, getSpecificPlan, getCheckout } = useContext(EntityContext)

  const navigate = useNavigate();

  const handleNavigateToPlans = () => {
      navigate('/plans');
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  useEffect(() => {
    getASpecificPlan()
    getCheckouts()
    }, [entity.plan.name])


    const getASpecificPlan = useCallback(async () => {
        getSpecificPlan(entity.plan.id)
            .then((data) => {
                setPlanInfo(data)
            })
            }, [])

    const getCheckouts = useCallback(async () => {
        getCheckout()
            .then((data) => {
                setCheckoutsList(data)
            })
            }, [])


  return (
    <>
      <Helmet>
        <title> Meu Plano </title>
      </Helmet>

      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Meu Plano
          </Typography>
        </Stack>

        <Card >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Informações
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} >
                <Typography variant="subtitle2" noWrap>
                    Plano: <Label>{entity.plan.name}</Label>
                </Typography>
                <Button onClick={handleNavigateToPlans}>Editar</Button>
            </Stack>
            <Typography variant="subtitle2" noWrap>
              Usuarios Permitidos: {entity.plan.users_maximum}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Franquia Mensal: R$ {planInfo ? planInfo.envelope_cost.fixed_cost : '--'}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Quantidade de envelopes por mês: {planInfo ? planInfo.envelope_cost.monthly_maximum : ''}
            </Typography>

            {   planInfo && planInfo.envelope_cost.additional_envelopes_allowed ?
              <Typography variant="subtitle2">
                Permitido envelope adicional: {planInfo.additional_envelopes_allowed ? 'Sim' : 'Não'}
              </Typography>
            : <></> }

            {   planInfo && planInfo.envelope_cost.variable_cost ?
                <Typography variant="subtitle2" noWrap>
                    Valor do envelope adicional: R$ {planInfo.additional_envelopes_allowed  ? planInfo.envelope_cost.variable_cost : '--'}
                </Typography> : <></>

            }

            {   planInfo && entity.plan.card_required ?
                <Typography variant="subtitle2" noWrap>
                    Forma de Pagamento: {entity.plan.card_required ? 'Cartão' : 'Boleto'}
                </Typography> : <></>
            }   
 
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2}  sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Pagamentos
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ p: 3 }}  direction="row" justifyContent="space-between">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Checkouts" {...a11yProps(0)}/>
                {/* <Tab label="Faturas" {...a11yProps(2)}/> */}
            </Tabs>
          </Stack>
          <TabPanel value={value} index={0}>
            <Stack direction="column" alignItems="stretch" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}
              divider={<Divider orientation="vertical" flexItem style={{width:'100%'}} />}>
              {checkousList.length === 0 ? <></>:
              checkousList.map((checkout) => (
              <Stack direction="row" spacing={2} sx={{ p: 1 }} justifyContent="space-between">
                <Typography variant="subtitle2" noWrap>
                  ID: {checkout.id}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  Plano: {checkout.plan.name}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  Valor: {checkout.plan.envelope_cost.fixed_cost}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  Data: {checkout.created_at.substring(0, 10)}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  Status: <Label>{checkout.status}</Label>
                </Typography>
              </Stack>
              ))}
            </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <InvoicesList/>
            </TabPanel>
          
        </Card>
      </Container>
    </>
  );
}
