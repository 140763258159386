import React, {useState, useEffect, useCallback, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  TextField,
  Stack,
  Button,
  MenuItem,
  Container,
} from '@mui/material';
// components
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
// sections
import { EnvelopeListHead, EnvelopeListToolbar } from '../../sections/@dashboard/envelope/index';

import fetchApi from '../../axios/config'

import { EntityContext } from '../../entity';

// ----------------------------------------------------------------------

export default function CreateEnvelope(props) {
    const [envelopeName, setEnvelopeName] = useState('');
    const [newEnvelope, setNewEnvelope] = useState(true);

    const { getEnvelope } = useContext(EntityContext)

    useEffect(() => {
      getEnvelopeName()

        if (props.envelopeKey) {
            setNewEnvelope(false)
        } else {
            setNewEnvelope(true)
        }

      }, [])
  
      const getEnvelopeName = useCallback(async () => {
      
        getEnvelope(props.envelopeKey)
        .then((data) => {
          
          setEnvelopeName(data.envelope.envelope_name)
        })
      
        }, [])
  
    
    const handleSubmission = () => {
        if (envelopeName) {
             props.createEnvelope(envelopeName)
        }
    }

    const handleEnvelopeNameChange = (newEnvelopeName) => {
        setEnvelopeName(newEnvelopeName)
    }

    return (
        <>
        <Helmet>
            <title> User </title>
        </Helmet>

        <Container>
        <Stack spacing={2} justifyContent="space-between" mb={5}>
            <TextField 
            id="outlined-basic" 
            label="Nome do envelope" 
            variant="outlined"
            value={envelopeName}
            disabled={!newEnvelope}
            onChange={e => handleEnvelopeNameChange(e.target.value)}/>
            { props.envelopeKey ? 
                <></>:
                <Button variant="contained"
                onClick={handleSubmission}>
                    Criar envelope
                </Button>   }
        </Stack>

        </Container>
        </>
    );
}
