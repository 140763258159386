import './Loading.css'

const Loading=()=>{
  return(
    <div className="spinner-container">
      <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="25"
          y="25"
          width="50"
          height="50"
          fill="lightblue"
          stroke="none"
        >
          <animateTransform
            attributeName="transform" 
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="0.5s" // Adjust duration as needed
            repeatCount="indefinite"
          />
        </rect>
      </svg>
      <div className="loading-text">Loading ...</div>
    </div>
  )
}
export default Loading;
