import React, { createContext, useState } from 'react'

import apiFetch from './axios/config'

const EntityContext = createContext()

const Entity = (props) => {

    const [entity, setEntity] = useState('');
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getEntities = async (token) => {
      return new Promise((resolve, reject) => {
        try {
          setLoading(true);
          apiFetch.get(
            `/user`,
            { headers: { Authorization: `Bearer ${token}` } }
          ).then((response) => {
            if (response.status === 404) {
              setLoading(false);
              reject(new Error('Not Found'));
              return;
            }
    
            const data = response.data;
            
            setLoading(false);
            resolve(data);
          });
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          reject(error);
        }
      });
    };

    const getEntity = async () => {
      try {
        setLoading(true);
        const response = await apiFetch.get(
          `/entity`,
          { headers: { Authorization: `${entity.api_key}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const setEntityPlan = async (token, planKey) => {

      const payload = {
        "entity_key": entity.entity_key,
        "plan_id": planKey
      }

      try {
        setLoading(true);
        const response = await apiFetch.patch(
          `/entity/plan`,  payload, 
          { headers: { Authorization: `Bearer ${token}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        setErro(false)
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const addUserToEntity = async (token, userDocumentNumber, roleType) => {

      const payload = {
        "entity_key": entity.entity_key,
        "user_document_number": userDocumentNumber,
        "role_type": roleType
      }

      try {
        setLoading(true);
        const response = await apiFetch.post(
          `/entity/user`,  payload, 
          { headers: { Authorization: `Bearer ${token}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const RemoveUserFromEntity = async (token, userKey) => {

      try {
        setLoading(true);
        const response = await apiFetch.delete(
          `/entity/user?entity_key=${entity.entity_key}&user_key=${userKey}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const DeleteSigner = async (signerKey) => {

      try {
        setLoading(true);
        const response = await apiFetch.delete(
          `/signer?signer_key=${signerKey}`,
          { headers: { Authorization: entity.api_key } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const getPlan = async () => {
      try {
        setLoading(true);
        const response = await apiFetch.get(
          `/plan`,
          { headers: { Authorization: `${entity.api_key}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const getCheckout = async () => {
      try {
        setLoading(true);
        const response = await apiFetch.get(
          `/checkout`,
          { headers: { Authorization: `${entity.api_key}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const getinvoices = async () => {
      try {
        setLoading(true);
        const response = await apiFetch.get(
          `/checkout/invoices`,
          { headers: { Authorization: `${entity.api_key}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };

    const getSpecificPlan = async (id) => {
      try {
        setLoading(true);
        const response = await apiFetch.get(
          `/plan?id=${id}`,
          { headers: { Authorization: `${entity.api_key}` } },
        );
    
        if (response.status === 404) {
          setLoading(false);
          setErro(true)
          return { error: new Error('Not Found') };
        }
    
        const data = response.data;
        
        setLoading(false);
        return data;
      } catch (error) {
        // Log the error for debugging purposes (optional)
        console.error('Error fetching:', error);
        setLoading(false);
        setErro(true);
        setErrorMessage(error.response.data.detail)
        return { error };
      }
    };
    

      const getEnvelopeList = async () => {
        try {
          setLoading(true)
          const response = await apiFetch.get(
            `/envelope`,  { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const getXpressSignaturesList = async () => {
        try {
          setLoading(true)
          const response = await apiFetch.get(
            `/signature/get/xpress`,  { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const createEnvelope = async (payload) => {
        try {
          setLoading(true)
          const response = await apiFetch.post(
            `/envelope`,  payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          if (response.status === 400) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Error') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
    };

      const getEnvelope = async (envelopeKey) => {
        if (!envelopeKey) {
          setErro(true)
          return { error: new Error('Missing Params') };
        }
          try {
            setLoading(true)
            const response = await apiFetch.get(
            `/envelope?envelope_key=${envelopeKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
            );
    
            if (response.status === 404) {
              setLoading(false);
              setErro(true)
              return { error: new Error('Not Found') };
            }
        
            const data = response.data;
            
            setLoading(false);
            return data;
          } catch (error) {
            // Log the error for debugging purposes (optional)
            console.error('Error fetching:', error);
            setLoading(false);
            setErro(true);
            setErrorMessage(error.response.data.detail)
            return { error };
          }
      }

      const getTemplates = async () => {
        try {
          setLoading(true)
          const response = await apiFetch.get(
          `/template`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const getTemplate = async (templateKey) => {
        try {
          setLoading(true)
          const response = await apiFetch.get(
          `/template?template_key=${templateKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          )
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const deleteTemplate = async (templateKey) => {
      new Promise((resolve, reject) => {
        try {
          setLoading(true)
          apiFetch.delete(
          `/template?template_key=${templateKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            if (response.status === 404) {
              setLoading(false);
              reject(new Error('Not Found'));
              return;
            }
    
            const data = response.data;
            
            setLoading(false);
            resolve(data);
          });
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          reject(error);
        }
      });
    };

      const vinculateDocumentToEnvelope = async (documentKey, envelopeKey) => 
      new Promise((resolve, reject) => {

        const payload = {
          "envelope_key": envelopeKey,
          "document_key": documentKey
          }
      
        try {
          setLoading(true)
          apiFetch.post(
          `/envelope/document`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
            setLoading(false)
            resolve(true)
          })
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      
      })

      const removeDocumentToEnvelope = async (documentKey, envelopeKey) => 
        new Promise((resolve, reject) => {
  
          const payload = {
            "envelope_key": envelopeKey,
            "document_key": documentKey
            }
        
          try {
            setLoading(true)
            apiFetch.delete(
            `/envelope/document`, { data: payload, headers: { 'Authorization': `${entity.api_key}` } }
            ).then((response) => {
              const data = response.data;
              setLoading(false)
              resolve(true)
            })
          } catch (error) {
            setLoading(false)
            reject(error)
          }
        
        })

      const createDocument = async (name, base64) =>
      new Promise((resolve, reject) => {

        const formatedName =  name.replace(/\.[^/.]+$/, "")
    
        const payload = {
          "document_name":formatedName,
          "base64": base64
          }
      
        try {
          setLoading(true)
          apiFetch.post(
          `/document`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
    
            const documentKey = data.document_key
      
            const newFile = {
            "documentKey": documentKey,
            "documentName": formatedName
            }
            setLoading(false)
            resolve(newFile)
          })        
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })
      
      const createTemplate = async (name, base64) =>
      new Promise((resolve, reject) => {

        const formatedName =  name.replace(/\.[^/.]+$/, "")
    
        const payload = {
          "template_name":formatedName,
          "base64": base64
          }
      
        try {
          setLoading(true)
          apiFetch.post(
          `/template`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
    
            const documentKey = data.template_key
      
            const newFile = {
            "templateKey": documentKey,
            "documentName": formatedName
            }
            setLoading(false)
            resolve(newFile)
          })        
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })
  
        const createBatchSignature = async (token, envelopeSignerKeyList) => {

          const payload = {
            "envelope_signer_key": envelopeSignerKeyList
          }

          try {
            setLoading(true)
            const response = await apiFetch.post(
              `/signature/batch`, payload, { 'headers': { 'Authorization': `Bearer ${token}` } }
            );
      
            if (response.status === 404) {
              setLoading(false);
              setErro(true)
              return { error: new Error('Not Found') };
            }
        
            const data = response.data;
            
            setLoading(false);
            return data;
          } catch (error) {
            // Log the error for debugging purposes (optional)
            console.error('Error fetching:', error);
            setLoading(false);
            setErro(true);
            setErrorMessage(error.response.data.detail)
            return { error };
          }
        }

        const requestBatchSignatureToken = async (token, batchSignatureKey) => {
          
          const payload = {
            "signature_batch_key": batchSignatureKey
          }

          try {
            setLoading(true)
            const response = await apiFetch.post(
              `/signature/batch/token`, payload, { 'headers': { 'Authorization': `Bearer ${token}` } }
            );
      
            if (response.status === 404) {
              setLoading(false);
              setErro(true)
              return { error: new Error('Not Found') };
            }
        
            const data = response.data;
            
            setLoading(false);
            return data;
          } catch (error) {
            // Log the error for debugging purposes (optional)
            console.error('Error fetching:', error);
            setLoading(false);
            setErro(true);
            setErrorMessage(error.response.data.detail)
            return { error };
          }
        };

      const createWebhook = async (url, header) =>
      new Promise((resolve, reject) => {

        const headerJson = JSON.parse(header);
    
        const payload = {
          "url": url,
          "headers": headerJson
        }
      
        try {
          setLoading(true)
          apiFetch.post(
          `/webhook`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
            setLoading(false)
            resolve(data)
          })        
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })

      const deleteWebhook = async (webhookConfigurationKey) => 
      new Promise((resolve, reject) => {
        try {
          setLoading(true)
          apiFetch.delete(
          `/webhook?webhook_configuration_key=${webhookConfigurationKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
            
            setLoading(false)
            resolve(data)
          })            
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })

      const getWebhooks = async () => 
      new Promise((resolve, reject) => {
        try {
          setLoading(true)
          apiFetch.get(
          `/webhook`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
            
            setLoading(false)
            resolve(data)
          })            
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })

      const getSigners = async () => 
      new Promise((resolve, reject) => {
        try {
          setLoading(true)
          apiFetch.get(
          `/signer`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            if (response.status === 404) {
              setLoading(false);
              reject(new Error('Not Found'));
              return;
            }
    
            const data = response.data;
            
            setLoading(false);
            resolve(data);
          });
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          reject(error);
        }
      });

      const getSignerByDocument = async (signerDocumentNumber) => 
      new Promise((resolve, reject) => {
        try {
          setLoading(true)
          apiFetch.get(
          `/signer?signer_document_number=${signerDocumentNumber}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            if (response.status === 404) {
              setLoading(false);
              reject(new Error('Not Found'));
              return;
            }
    
            const data = response.data;
            
            setLoading(false);
            resolve(data);
          });
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          reject(error);
        }
      });

      const createSigner = async (name, email, documentNumber) => {
    
        const payload = {
          "signer_email": email,
          "signer_name": name,
          "signer_document_number": documentNumber
        }
      
        try {
          setLoading(true)
          const response = await apiFetch.post(
          `/signer`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          )          
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
          const signerKey = data.signer_key
      
          const newSigner = {
          "signerKey": signerKey,
          "signerName": name,
          "signerDocumentNumber": documentNumber
          }

          
          setLoading(false);
          return newSigner;

        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const getGroups = async () => {

        try {
          setLoading(true)
          const response = await apiFetch.get(
          `/group`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          )          
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
          
          setLoading(false);
          return data;

        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data[0].detail[0].msg)
          return { error };
        }
      }

      const getGroup = async (groupKey) => {

        try {
          setLoading(true)
          const response = await apiFetch.get(
          `/group?group_key=${groupKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          )          
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
          
          setLoading(false);
          return data;

        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data[0].detail[0].msg)
          return { error };
        }
      }

      const getEnvelopeGroup = async (envelopeGroupKey) => {

        try {
          setLoading(true)
          const response = await apiFetch.get(
          `envelope-group?envelope_group_key=${envelopeGroupKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          )          
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
          
          setLoading(false);
          return data;

        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data[0].detail[0].msg)
          return { error };
        }
      }

      const getGroupByName = async (groupName) => {
        try {
          setLoading(true)
          const response = await apiFetch.get(
            `/group?group_name=${groupName}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
      
          const data = response.data;
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }


      const createGroup = async (groupName) => {

        const payload = {
          "group_name": groupName
        }

        try {
          setLoading(true)
          const response = await apiFetch.post(
            `/group`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
    
          const groupKey = data.group_key
    
          const newGroup = {
          "groupKey": groupKey,
          "groupName": groupName
          }

          
          setLoading(false);
          return newGroup;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const resendEmail = async (envelopeSignerKey) => {

        const payload = {}

        try {
          setLoading(true)
          const response = await apiFetch.post(
            `/signature/send/${envelopeSignerKey}`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
    
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const editGroupApi = async (groupKey, signersMinimum) => {

        let payload = null

        if (signersMinimum === "0") {
          payload = {
            "group_key": groupKey
          }
        } else{
          payload = {
            "group_key": groupKey,
            "signers_minimum": signersMinimum
          }
        }

        try {
          setLoading(true)
          const response = await apiFetch.patch(
            `/group`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
          const data = response.data;

          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const RemoveGroupApi = async (envelopeKey, envelopeGroupKey) => {

        const payload = {
          "envelope_key": "string",
          "envelope_group_key": "string"
        }

        try {
          setLoading(true)
          const response = await apiFetch.patch(
            `/group`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }
          const data = response.data;

          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const VinculateGroupToEnvelope = async (envelopeKey, groupKey, signatureType) =>
        new Promise((resolve, reject) => {
      
          const payload = {
            "envelope_key": envelopeKey,
            "signers": [
              {
                "group_key": groupKey,
                "signature_type": signatureType
              }
            ]
          }
        
          try {
            setLoading(true)
            apiFetch.post(
            `/envelope/group`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
            ).then((response) => {
              const data = response.data;
              setLoading(false)
              resolve(data)
            })        
          } catch (error) {
            setLoading(false)
            reject(error)
          }
        })

      const vinculateSignerToGroup = async (groupKey, signerKey, roleType, required) => {

        const payload = {
          "signer_key": signerKey,
          "group_key": groupKey,
          "role_type": roleType,
          "required": required
        }

        try {
          setLoading(true)
          const response = await apiFetch.post(
            `/group/signer`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          );
    
          if (response.status === 404) {
            setLoading(false);
            setErro(true)
            return { error: new Error('Not Found') };
          }

          const data = response.data;
    
          
          setLoading(false);
          return data;
        } catch (error) {
          // Log the error for debugging purposes (optional)
          console.error('Error fetching:', error);
          setLoading(false);
          setErro(true);
          setErrorMessage(error.response.data.detail)
          return { error };
        }
      }

      const DeleteGroupFromEnvelope = async (envelopeKey, groupKey) =>
        new Promise((resolve, reject) => {
      
          const payload = {
            "envelope_key": envelopeKey,
            "envelope_group_key": groupKey
          }
        
          try {
            setLoading(true)
            apiFetch.delete(
            `/envelope/group`, { data: payload, headers: { 'Authorization': `${entity.api_key}` } }
            ).then((response) => {
              const data = response.data;
              setLoading(false)
              resolve(data)
            })        
          } catch (error) {
            setLoading(false)
            reject(error)
          }
        })

      const sendToSignature = async (envelopeKey) =>
      new Promise((resolve, reject) => {
    
        const payload = {}
      
        try {
          setLoading(true)
          apiFetch.post(
          `/signature/send?envelope_key=${envelopeKey}`, payload, { 'headers': { 'Authorization': `${entity.api_key}` } }
          ).then((response) => {
            const data = response.data;
            setLoading(false)
            resolve(data)
          })        
        } catch (error) {
          setLoading(false)
          reject(error)
        }
      })

        const sendSignature = async (otp, signerKey, ipData, fingerprint) => {

          const payload = {
            "token": otp,
            "envelope_signer_key": signerKey,
            "evidence": {
                "ip": ipData,
                "fingerprint": fingerprint
            }
          }

          try {
            setLoading(true)
            const response = await apiFetch.post(
              `/signature/sign`, payload,  { 'headers': { 'Authorization': `${entity.api_key}` } }
            );
      
            if (response.status === 404) {
              setLoading(false);
              setErro(true)
              setErrorMessage(response.data.detail)
              return { error: new Error('Not Found') };
            }
        
            const data = response.data;
            
            setLoading(false);
            return data;
          } catch (error) {
            // Log the error for debugging purposes (optional)
            console.error('Error fetching:', error);
            setLoading(false);
            setErro(true);
            setErrorMessage(error.response.data.detail)
            return null;
          }
        }

        const sendBatchSignature = async (token, otp, batchKey, ipData, fingerprint) =>
          new Promise((resolve, reject) => {
            const payload = {
              "token": otp,
              "signature_batch_key": batchKey,
              "evidence": {
                  "ip": ipData,
                  "fingerprint": fingerprint
              }
            }
          try {
          setLoading(true)
          apiFetch.post(
              `/signature/batch/sign`, payload, { 'headers': { 'Authorization': `Bearer ${token}` } }
          ).then((response) => {
            const data = response.data;
            setLoading(false)
            resolve(data)
          })
          } catch (error) {
            setLoading(false)
            reject(error)
          }
          })

      const requestSignatureToken = async (payload) => 
        new Promise((resolve, reject) => {
          try {
            setLoading(true)
            apiFetch.post(
              `/signature/token`, payload
            ).then((response) => {
              const data = response.data;
              
              setLoading(false)
              resolve(data)
            })
          } catch (error) {
            setLoading(false)
            reject(error)
          }
        });

        const deleteEnvelope = async (envelipeKey) => 
          new Promise((resolve, reject) => {
            try {
              setLoading(true)
              apiFetch.delete(
              `/envelope?envelope_key=${envelipeKey}`, { 'headers': { 'Authorization': `${entity.api_key}` } }
              ).then((response) => {
                const data = response.data;
                
                setLoading(false)
                resolve(data)
              })            
            } catch (error) {
              setLoading(false)
              reject(error)
            }
          })

    return(
        <EntityContext.Provider value={{ 
          getEntities
          , loading
          , entity
          , setEntity
          , getEntity
          , getEnvelopeList
          , setEntityPlan
          , addUserToEntity
          , RemoveUserFromEntity
          , createEnvelope
          , getEnvelope
          , createDocument
          , vinculateDocumentToEnvelope
          , removeDocumentToEnvelope
          , getTemplates
          , getTemplate
          , deleteTemplate
          , createTemplate
          , getWebhooks
          , createWebhook
          , deleteWebhook
          , getSigners
          , DeleteSigner
          , getSignerByDocument
          , createSigner
          , getGroups
          , getGroup
          , getGroupByName
          , getEnvelopeGroup
          , editGroupApi
          , createGroup
          , VinculateGroupToEnvelope
          , vinculateSignerToGroup
          , sendToSignature
          , sendSignature
          , sendBatchSignature
          , requestSignatureToken
          , deleteEnvelope
          , DeleteGroupFromEnvelope
          , createBatchSignature
          , requestBatchSignatureToken
          , getPlan
          , getXpressSignaturesList
          , getSpecificPlan
          , getCheckout
          , getinvoices
          , resendEmail
          , erro 
          , setErro
          , errorMessage}}>
            {props.children}
        </EntityContext.Provider>
    )

}

export { Entity, EntityContext }