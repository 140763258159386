import React, { useEffect, useState, useCallback, useContext } from "react";

import {useNavigate} from 'react-router-dom';

import {
    Button
  } from '@mui/material';

import { getFingerprint } from "../../utils/getFingerprint";  

import { EntityContext } from '../../entity';
import { AccountContext } from '../../account';

import useIP from "../../sections/auth/login/useIp";

function SignatureButton(props) {

    const ip = useIP();

    const { sendSignature, sendBatchSignature } = useContext(EntityContext)
    const { getSession } = useContext(AccountContext)

  const [fingerprint, setFingerprint] = useState(null);
  const [ipData, setIpData] = useState(null);
  const [showReport, setShowReport] = useState(true);
  const [token, setToken] = useState(null)

  useEffect(() => {
    getSession()
        .then((data) => {
            setToken(data.idToken.jwtToken)
        })
    }, [])

  const navigate = useNavigate();

  const navigateToSignatureFinished = () => {
    // 👇️ navigate to /contacts
    navigate(`/signature/finish`);
  };

    useEffect(() => {
        if (showReport) {
        fetch("https://extreme-ip-lookup.com/json")           // Get the IP data
            .then(res => res.json())
            .then(ip => Promise.all([ip, getFingerprint()]))    // Get the fingerprint
            .then(([ip, fp]) => {
            setFingerprint(fp);                               // Update the state
            setIpData(ip);
            setShowReport(false);
            });
        }
    }, [showReport]);
    
    
    const handleSubmission = async () => {

        if (props.signerKey) {
            const validateSignature = await sendSignature(props.otp, props.signerKey, ip, fingerprint)

            if (validateSignature) {
    
                navigateToSignatureFinished()
    
            }
        }

        if (props.batchKey) {
            const validateSignature = await sendBatchSignature(token, props.otp, props.batchKey, ipData, fingerprint)

            if (validateSignature) {
    
                navigateToSignatureFinished()
    
            }
        }


    }

    return (
        <div>
        {ipData && fingerprint ? (
            <div>
                <Button 
                    variant="contained"
                    onClick={_ => handleSubmission()}>
                    Assinar
                </Button>
            </div>
        ) : (
            <div>
            <h2>Please wait...</h2>
            </div>
        )}
        </div>

    );
}

export default SignatureButton;