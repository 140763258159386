import { useState, useEffect, useContext, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button, Card } from '@mui/material';
// components
import Iconify from '../components/iconify';

import { EntityContext } from '../entity';
import { AccountContext } from '../account';

import AppWidgetSummary from '../sections/@dashboard/app/AppWidgetSummary'

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [token, setToken] = useState(null)
  const [currentEntity, setCurrentEntity] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  const { getEntity, getEntities } = useContext(EntityContext)
  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
        .then((data) => {
            setToken(data.idToken.jwtToken)
        })
    }, [])

  useEffect(() => {
    getCurrentEntity()
    GetUserInfo()
    }, [token])

    const GetUserInfo = useCallback(async () => {

      handleSetUserInfo(token)

    }, [token])

  const handleSetUserInfo = async (token) => {
    getEntities(token)
      .then(data => {
        
        setUserInfo(data.user)
      })
  }

    const getCurrentEntity = useCallback(async () => {
	
      getEntity()
          .then((data) => {
            
            setCurrentEntity(data.entity)
          })
              
        }, [])

  const navigateToNewEnvelope = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/envelope');
  };

  const navigateToEnvelopes = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/envelopes');
  };

  const navigateToPendingSignatures = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/pendingSignatures');
  };

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
            Dashboard
            </Typography>
            { currentEntity ? 
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={navigateToNewEnvelope}>
                Novo Envelope
              </Button> :
              <></> }
        </Stack>
        
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              link={navigateToEnvelopes}
              title="Espaço Ultilizado (GB)"
              total={currentEntity ? `${currentEntity.info.storage_consumption}` : `0`}
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            />
          </Grid>  
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              link={navigateToEnvelopes}
              title="Envelopes Enviados Este Mês"
              total={currentEntity ? `${currentEntity.info.envelopes_this_month}` : `0`}
              maximum={currentEntity ? `${currentEntity.plan.envelope_cost.monthly_maximum}` : `0`}
              color="success"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            />
          </Grid> 
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              link={navigateToPendingSignatures}
              title="Assinaturas Pendentes"
              total={userInfo ? `${userInfo.pending_signatures.length}` : `0` }
              color="warning"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            />
          </Grid> 
        </Grid>   
      </Container>
    </>
  );
}
