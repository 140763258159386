import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
	AuthenticationDetails,
	CognitoUser,
} from 'amazon-cognito-identity-js';

// components
import Iconify from '../../../components/iconify';

import { AccountContext } from '../../../account';

// ----------------------------------------------------------------------

export default function ChangePasswordForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("")
  const [password, setPassword] = useState("")

  const handleClick = () => {

    props.veryfyForgotPassword(password, newPassword)

    // Alterar senha guardado
    // getSession().then(({user}) => {
    //     user.ChangePassword(password, newPassword, (err, result) => {
    //         if(err){
    //             
    //         } else {
    //             console.log(result)
    //         }
    //     })
    // })
    
  };

  return (
    <>
      <Stack spacing={3}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Enviamos um token de acesso para o seu email
          </Typography>
        <TextField
          name="password"
          label="Codigo de Verificação"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          name="newPassword"
          label="Nova Senha"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Alterar Senha
      </LoadingButton>
    </>
  );
}
