import { Helmet } from 'react-helmet-async';
import { useState, useContext, useCallback, useEffect } from 'react';
// @mui
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material';
import Iconify from '../components/iconify';
// components
import { TemplateList } from '../sections/@dashboard/templates';

import {EntityContext} from '../entity'

// ----------------------------------------------------------------------

const AddTemplateCard = (props) => {
    return (
      <Stack justifyContent="space-between" mb={5}>
        <Card>
          <Typography sx={{ m: 2 }} >Novo Template</Typography>
          <Stack direction="row" alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
            <TextField sx={{ m: 2 }}
              id="outlined-basic" 
              label="Apelido" 
              variant="outlined"
              value={props.templateName}
              onChange={e => props.setTemplateName(e.target.value)}/>
            <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
            Adicionar
            <input 
                hidden accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                multiple 
                type="file" 
                name="file"
                onChange={props.changeHandler}/>
            </Button>
          </Stack>
        </Card>
      </Stack>
    )
  }

export default function TemplatesPage() {
  const [templateName, setTemplateName] = useState('');
  const [templatesList, setTemplatesList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const { getTemplates, createTemplate, deleteTemplate, getTemplate } = useContext(EntityContext)

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleToggleAddTemplate = () => {
    setShowAddTemplate(!showAddTemplate)
  }

  const getDocuments = useCallback(async () => {
	
        getTemplates()
        .then((data) => {
          
          if (data) {
            setTemplatesList(data.templates)
          }
        }) 
	
	  }, [])

    useEffect(() => {
    getDocuments()
    }, [])

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result);
        }

        fileReader.onError = (error) => {
            reject(error)
        }

    })
  }

    function getExtension(filename) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }

    function isDocx(filename) {
        const ext = getExtension(filename);

        switch (ext.toLowerCase()) {
            case 'docx':
                return true
            default:
                return false
        }

    }

  const changeHandler = async (event) => {

    const newDoc = event.target.files[0]

    const validFile = isDocx(newDoc.name) 

    if (validFile) {

      const base64Doc = await convertBase64(newDoc)

      const validBase64Doc = base64Doc.split(',')
      

      const newDocumentRecord = await createTemplate(templateName, validBase64Doc[validBase64Doc.length - 1])

      if(newDocumentRecord) {

        getDocuments()
        handleToggleAddTemplate()

      }
    }

  };

  return (
    <>
      <Helmet>
        <title> Templates </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Templates
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleToggleAddTemplate}>
            Adicionar template
          </Button>
        </Stack>

        { showAddTemplate ? <AddTemplateCard setTemplateName={setTemplateName} templateName={templateName} changeHandler={changeHandler}/> : <></> }

        { templatesList ? <TemplateList templatesList={templatesList} /> : <></> }
      </Container>
    </>
  );
}
