import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Navigate } from 'react-router-dom';
import Router from './routes'
import apiFetch from './axios/config'

import { AccountContext } from './account';



const ProtectedRoutes = () => {
    const [authenticatedUser, setAuthenticatedUser] = useState(false)
    const [token, setToken] = useState(null)

    const { getSession } = useContext(AccountContext)

    const getEntity = useCallback(() => {
    
        try {

          // apiFetch.defaults.headers.common.Authorization = `Bearer ${token}`
          const response = apiFetch.get(
            `/user`,  { 'headers': { 'Authorization': `Bearer ${token}` } }
          ).then((response) => {
            const data = response.data;
            
            return data
          })
      
        } catch (error) {
          console.log(error);
        }
    
      }, [token, ])

    useEffect(() => {
        getSession()
            .then((data) => {
                setAuthenticatedUser(true)
                // console.log('DATA: ', data.idToken.jwtToken)
                setToken(data.idToken.jwtToken)
            })
    }, [])

    return <Router isAuth={authenticatedUser} />
}

export default ProtectedRoutes