import { useState, useEffect, useContext, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
// components
import Iconify from '../components/iconify';

import EntityList from '../sections/@dashboard/entities/EntityList';

import { EntityContext } from '../entity';
import { AccountContext } from '../account';

// ----------------------------------------------------------------------

export default function EntitiesPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [token, setToken] = useState(null)
  const [entities, setEntities] = useState(null)

  const { getEntities } = useContext(EntityContext)
  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
        .then((data) => {
            setToken(data.idToken.jwtToken)
        })
    }, [])

  useEffect(() => {
    GetEntity()
    }, [token])

    const GetEntity = useCallback(async () => {

      handleSetEntities(token)

    }, [token])

  const handleSetEntities = async (token) => {
    getEntities(token)
      .then(data => {
        
        setEntities(data.entities)
      })
  }

  const navigateToCreateEntity = () => {
    navigate('/dashboard/entity');
  };

  return (
    <>
      <Helmet>
        <title> Organizações </title>
      </Helmet>

      <Container maxWidth="xl">

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Organizações
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={navigateToCreateEntity}>
            Nova Organização
          </Button>
      </Stack>
      
      { entities ? <EntityList entities={entities} /> : <></> }
      
      
      </Container>
    </>
  );
}
