import React, {useState, useContext, useCallback} from 'react';
import { Helmet } from 'react-helmet-async';

import {
    Tabs,
    Tab,
    Typography,
    Box,
    Stack,
    Container,
    Grid
  } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';

import FileInput from '../components/file-input/index'
import CreateEnvelope from '../components/create-envelope/index'

import { EntityContext } from '../entity'

import {GroupList} from '../components/signer-input'

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NewEnvelope() {
  const { createEnvelope } = useContext(EntityContext)
  const [value, setValue] = useState(0);
  const [envelopeStep, setEnvelopeStep] = useState(true)
  const [documentStep, setDocumentStep] = useState(true)
  const [signerStep, setSignerStep] = useState(true)
  const [done, setDone] = useState(true)
  const [envelopeKey, setEnvelopeKey] = useState('')
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeStep = (newStep) => {
    setValue(newStep)
  }

  const postEnvelope = useCallback(async (name) => {

    const payload = {
        "envelope_name": name
      }

    try {
      createEnvelope(payload)
      .then(
        (data) => {

          if(data.error) {
            changeStep(0)
          } else {
            setEnvelopeKey(data.envelope_key)
        
            changeStep(1)
          }

        }
      )
    } catch (error) {
      console.log(error);
    }

  }, [])

  return (
    <>
    <Helmet>
        <title> Novo Envelope </title>
    </Helmet>

    <Container>
    <Grid container spacing={10} >
        <Grid item xs={12} sm={6} md={10}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" disabled={done}>
                <Tab label="Envelope" {...a11yProps(0)} disabled={envelopeStep}/>
                <Tab label="Documentos" {...a11yProps(1)} disabled={documentStep}/>
                <Tab label="Grupo de Assinantes" {...a11yProps(2)} disabled={signerStep}/>
                <Tab label="Fim" {...a11yProps(3)} disabled={done}/>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CreateEnvelope changeStep={changeStep} createEnvelope={postEnvelope} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <FileInput changeStep={changeStep} envelopeKey={envelopeKey} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <GroupList changeStep={changeStep} envelopeKey={envelopeKey}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Stack alignItems="center" justifyContent="center" m={12}>
                  <DoneAllIcon color="success" sx={{ fontSize: 100 }} />
                  <Typography>Envelope enviado para assinatura!</Typography>
              </Stack>
            </TabPanel>
          </Box>
        </Grid>
    </Grid>
    </Container>
    </>
  );
}
