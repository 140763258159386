import { Helmet } from 'react-helmet-async';

import {
  Stack, 
  Typography,
  Grid,
  Container
} from '@mui/material';

import ConstructionIcon from '@mui/icons-material/Construction';

function AutomaticSealPage(props) {

  return (
    <>
    
      <Helmet>
          <title> Chancela Automatica </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack alignItems="center" justifyContent="center" m={12}>
            <ConstructionIcon sx={{ fontSize: 100 }} />
            <Typography>Em construção ...</Typography>
        </Stack>
        </Container>
  </> 
  );
}

export default AutomaticSealPage;
