import { TextField } from '@mui/material';

export default function DocumentNumberField(props) {

    const handleDocumentNumberChange = (event) => {
        const formattedDocumentNumber = event.target.value.replace(/\D/g, ''); // Remove non-digits

        // Combine length and verification check
        const isValid = formattedDocumentNumber.length === 11;

        // Atualizar os props
        props.setDocumentNumber(formattedDocumentNumber);
        props.setDocumentNumberError(!isValid);
      };

  return (
    <TextField
        name="documentNumber"
        label="Numero de Documento"
        value={props.documentNumber}
        type="tel"
        error={props.documentNumberError}
        helperText={props.documentNumberError ? 'Documento inválido' : ''}
        onChange={handleDocumentNumberChange}
        inputProps={{ maxLength: 11 }}
    />
  );
}