import { useContext } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Button, Card, IconButton, Typography, Stack } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styled } from '@mui/material/styles';

import axios from 'axios'
import fileDownload from 'js-file-download'

import {EntityContext} from '../../../entity'

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

TemplateCard.propTypes = {
  Template: PropTypes.object,
};

export default function TemplateCard({ template }) {

    const { deleteTemplate, getTemplate } = useContext(EntityContext)

    const handleDownload = (templateKey, filename) => {

        getTemplate(templateKey).then(data => {
            const finalFilename = `${filename}.docx`
            downloadFile(data.url, finalFilename)
        })
      }

    const downloadFile = (filePath, fileName) => {
        fetch(filePath, {
          method: 'GET'
        })
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
              );
    
            document.body.appendChild(link);
    
            link.click();
    
            link.parentNode.removeChild(link);
          });
      };

  return (
    <Card >
      <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            Nome: {template.template_name}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            Key: {template.template_key}
          </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IconButton size="large" color="primary" onClick={_ => handleDownload(template.template_key, template.template_name)}>
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton size="large" color="primary" onClick={_ => handleDownload(template.template_key, template.template_name)}>
              <NoteAddIcon />
            </IconButton>
            <IconButton size="large" color="error" onClick={_ => deleteTemplate(template.template_key)}>
              <DeleteIcon />
            </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}
