import { faker } from '@faker-js/faker';
import React, {useState, useEffect, useCallback} from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  TextField
} from '@mui/material';

// components
import Iconify from '../iconify';

import NewGroupForm from './NewGroupForm'

export default function GroupCard(props) {

    const [openExistentGroup, setOpenExistentGroup] = useState(false)
    const [openNewGroup, setOpenNewGroup] = useState(false)
    const [groupNameToCheck, setGroupNameToCheck] = useState('')

    const HandleOpenForm = (event) => {

        switch(event) {
            case 'new':
                setOpenNewGroup(!openNewGroup)
                setOpenExistentGroup(false)
              break;
            case 'existent':
                setOpenExistentGroup(!openExistentGroup)
                setOpenNewGroup(false)
              break;
            default:
                setOpenNewGroup(false)
                setOpenExistentGroup(false)
          }

    }

    return (
            <Stack justifyContent="space-between" mb={5}>
                <Card>
                    <Typography sx={{ m: 2 }} >Adicionar Grupo</Typography>
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <TextField sx={{ m: 2 }}
                        id="outlined-basic" 
                        label="Nome do Grupo" 
                        value={groupNameToCheck}
                        onChange={e => setGroupNameToCheck(e.target.value)}
                        variant="outlined"/>
                        <Button 
                        sx={{ m: 2 }} 
                        variant="contained" 
                        component="label" 
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={_ => props.vinculateSignerToEnvelope(groupNameToCheck)}>
                        Adicionar
                        </Button>
                      </Stack>
                </Card>
            </Stack>
    );
}