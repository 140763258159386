// component
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import ApprovalIcon from '@mui/icons-material/Approval';
import BoltIcon from '@mui/icons-material/Bolt';
import HelpIcon from '@mui/icons-material/Help';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Envelopes',
    path: '/dashboard/envelopes',
    icon: <EmailIcon />
  },
  {
    title: 'Assinantes',
    path: '/dashboard/signers',
    icon: <PersonIcon />
  },
  {
    title: 'Grupos',
    path: '/dashboard/groups',
    icon: <GroupIcon />,
  },
  {
    title: 'Usuarios',
    path: '/dashboard/user',
    icon: <ManageAccountsIcon />
  },
  {
    title: 'Templates',
    path: '/dashboard/templates',
    icon: <DescriptionIcon />
  },
  // {
  //   title: 'Chancela Automatica',
  //   path: '/dashboard/automaticseal',
  //   icon: <ApprovalIcon />
  // },
  {
    title: 'Assinaturas Rapidas',
    path: '/dashboard/xpress/signature',
    icon: <BoltIcon />
  },
  {
    title: 'Configuração',
    path: '/dashboard/configuration',
    icon: <SettingsIcon />
  },
  {
    title: 'Ajuda',
    path: 'https://wa.me/5511985441304?text=Ol%C3%A1%2C%20preciso%20de%20suporte%20em%20chancelafacil.com.br',
    icon: <HelpIcon />
  },
];

export default navConfig;
