import { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../../components/iconify';

import apiFetch from '../../../axios/config';

import { AccountContext } from '../../../account';

import useIP from './useIp';
import { getFingerprint } from "../../../utils/getFingerprint"; 

// ----------------------------------------------------------------------

export default function SignInForm(props) {
  const navigate = useNavigate();
  const ip = useIP();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [givenName, setGivenName] = useState("")
  const [givenNameError, setGivenNameError] = useState(false)
  const [familyName, setFamilyName] = useState("")
  const [familyNameError, setFamilyNameError] = useState(false)
  const [documentNumber, setDocumentNumber] = useState("")
  const [documentNumberError, setDocumentNumberError] = useState(false);
  const [aceitoTermos, setAceitoTermos] = useState(false);
  const [aceitoTermosError, setAceitoTermosError] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');
  const [showReport, setShowReport] = useState(true);
  const [fingerprint, setFingerprint] = useState(null);

  const { register } = useContext(AccountContext)

  useEffect(() => {
    if (showReport) {
    fetch("https://extreme-ip-lookup.com/json")           // Get the IP data
        .then(res => res.json())
        .then(ip => Promise.all([ip, getFingerprint()]))    // Get the fingerprint
        .then(([ip, fp]) => {
        setFingerprint(fp);                               // Update the state
        setShowReport(false);
        });
    }
}, [showReport]);

  useEffect(() => {
    getTermsUrl()
    }, [])

	const getTermsUrl = useCallback(async () => {
	
      getTerms()
            .then((data) => {
              if (data) {
                setTermsUrl(data)
              }
            })
        
          }, [])

  const getTerms = async () => {
    try {
      const response = await apiFetch.get(`/user/terms`);
      const data = response.data;
      return data.term;
    } catch (error) {
      // Log the error for debugging purposes (optional)
      console.error('Error fetching:', error);
      return null;
    }
  }

  const agree = async () => {

    const payload = {
      "user_document_number": documentNumber,
      "user_email": email,
      "evidence": {
        'ip': ip,
        'fingerprint': fingerprint
      }
    }

    try {
      const response = await apiFetch.post(`/user/terms`, payload);
      const data = response.data;
      
      return data.term;
    } catch (error) {
      // Log the error for debugging purposes (optional)
      console.error('Error fetching:', error);
      return null;
    }
  }

  const handleCheckboxChange = (event) => {
    setAceitoTermos(event.target.checked);
    setAceitoTermosError(false)
  };
  
  // Separate validation function (example)
  const validateForm = () => {
    let isValid = true;

    if (documentNumber === '') {
      setDocumentNumberError(true);
      isValid = false;
    } else {
      setDocumentNumberError(false); // Clear previous error
    }

    if (familyName === '') {
      setFamilyNameError(true);
      isValid = false;
    } else {
      setFamilyNameError(false); // Clear previous error
    }

    if (givenName === '') {
      setGivenNameError(true);
      isValid = false;
    } else {
      setGivenNameError(false); // Clear previous error
    }

    if (email === '') {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false); // Clear previous error
    }

    if (password === '') {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false); // Clear previous error
    }

    if (!aceitoTermos) {
      setAceitoTermosError(true)
      isValid = false;
    } else {
      setAceitoTermosError(false); // Clear previous error
    }

    return isValid;
  };

  const handleClick = async () => {

    // Validate form fields and display errors if necessary
    const isValid = validateForm(); // Call a separate validation function
    if (!isValid) return; // Early return if validation fails
    
    if (!documentNumberError && !passwordError && !emailError && !givenNameError && !familyNameError && aceitoTermos) {
      const done = await register(documentNumber, email, password, givenName, familyName)

      if(done){
        agree().then(data => {
          window.location.reload();
        }
        )
      } 
    }    
  };

  const handleDocumentNumberChange = (event) => {
    const formattedDocumentNumber = event.target.value.replace(/\D/g, ''); // Remove non-digits
  
    // Ensure length is exactly 11 for valid CPF
    const isValid = formattedDocumentNumber.length === 11;
    setDocumentNumber(formattedDocumentNumber);
    setDocumentNumberError(!isValid);
  };

  const handlePasswordChange = (event) => {
    const formattedPassword = event.target.value;
  
    // Clear any previous error on input change
    setPasswordError(false); // Reset error state on change
  
    // More robust password validation using a regular expression
    const isValid = formattedPassword.length >= 8 &&
                   /[A-Z]/.test(formattedPassword) &&
                   /[a-z]/.test(formattedPassword) &&
                   /[0-9]/.test(formattedPassword) &&
                   /[!@#$%^&*]/.test(formattedPassword); // Common symbols
  
    setPassword(formattedPassword);
    setPasswordError(!isValid);
  };

  const handleGivenNameChange = (event) => {
    const formattedGivenName = event.target.value
  
    // Allow only letters, spaces, hyphens, and apostrophes
    const isValid = /^[A-Za-zÀ-ÁÇ-Ê-Í-Ó-Õ-Ú-Ü-á-ç-ê-í-ó-õ-ú-ü\s-']+$/.test(formattedGivenName);
    setGivenName(formattedGivenName);
    setGivenNameError(!isValid);
  };
  
  const handleFamilyNameChange = (event) => {
    const formattedFamilyName = event.target.value
  
    // Same validation rules as given name
    const isValid = /^[A-Za-zÀ-ÁÇ-Ê-Í-Ó-Õ-Ú-Ü-á-ç-ê-í-ó-õ-ú-ü\s-']+$/.test(formattedFamilyName);
    setFamilyName(formattedFamilyName);
    setFamilyNameError(!isValid);
  };
  
  const handleEmailChange = (event) => {
    const formattedEmail = event.target.value.trim(); // Trim leading/trailing spaces
  
    // Basic email format validation using a regular expression
    const isValid = /^[a-zA-Z0-9.!#$%&'*+/=?'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formattedEmail);
    setEmail(formattedEmail);
    setEmailError(!isValid);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="documentNumber"
          label="CPF"
          value={documentNumber}
          type="tel"
          error={documentNumberError}
          helperText={documentNumberError ? 'Documento inválido' : ''}
          onChange={handleDocumentNumberChange}
          inputProps={{ maxLength: 11 }}
        />
        <TextField name="name" label="Nome" value={givenName}
        error={givenNameError}
        onChange={handleGivenNameChange}
        helperText={givenNameError ? 'Nome inválido: apenas letras e espaços' : ''}/>
        <TextField name="familyName" label="Sobrenome" value={familyName}
        error={familyNameError}
        helperText={familyNameError ? 'Sobrenome inválido: apenas letras e espaços' : ''}
        onChange={handleFamilyNameChange}/>
        <TextField name="email" label="Email" type="email" value={email}
        error={emailError}
        onChange={handleEmailChange}
        helperText={emailError ? 'Email inválido' : ''}/>
        <TextField
          name="password"
          label="Senha"
          error={passwordError}
          helperText={passwordError ? 'Senha fraca: mínimo 8 caracteres com letras maiúsculas, minúsculas, números e símbolos' : ''}
          value={password}
          onChange={handlePasswordChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row">
          <Checkbox 
          checked={aceitoTermos} onChange={handleCheckboxChange}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
          <Typography variant="body1">
            Declaro que li e aceito os {` `}
            {<Link to={termsUrl} target="_blank">termos de uso e política de privacidade</Link>}.
          </Typography>
        </Stack>
        { aceitoTermosError ? <Typography variant="body2" color="red">
            Você precisa aceitar os termos para continuar.
        </Typography> : <></>}
      </Stack>

      <LoadingButton disabled={documentNumberError || passwordError || emailError || givenNameError || familyNameError}
       sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Registrar
      </LoadingButton>
    </>
  );
}
