import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppWidgetSummary
} from '../sections/@dashboard/app';

import FileUploadPage from '../components/file-input/index'

// ----------------------------------------------------------------------

export default function SignatureManagementMainPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToNewEnvelope = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/envelope');
  };

  return (
    <>
      <Helmet>
        <title> Home </title>
      </Helmet>

      <Container maxWidth="xl">

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Signature Manager
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={navigateToNewEnvelope}>
            Novo Envelope
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Documentos Pendentes" total={7} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Documentos Assinados" total={14} color="info" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Consumo Mensal" total={21} color="warning" />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppTasks
              title="Envelopes"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
