import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Stack, Button } from '@mui/material';

// ----------------------------------------------------------------------

PlanCard.propTypes = {
    plan: PropTypes.object,
    SelectPlan: PropTypes.func
};

export default function PlanCard({ plan, SelectPlan }) {

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle1" noWrap>
        {plan.name}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            {plan.detail}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Usuarios permitidos: {plan.users_maximum}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Franquia mensal: {plan.envelope_cost.monthly_maximum === 0 ? "Ilimitado" : `${plan.envelope_cost.monthly_maximum} envelopes`}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Valor da franquia {plan.envelope_cost.billing_type === 'monthly' ? 'mensal' : 'anual'}: R$ {plan.envelope_cost.fixed_cost.toFixed(2)}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Permitido envelope adicional: {plan.additional_envelopes_allowed ? 'Sim' : 'Não'}
          </Typography>
        </Stack> 
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            Valor por envelope adicional: R$ {plan.additional_envelopes_allowed ? plan.envelope_cost.variable_cost.toFixed(2) : '--'}
          </Typography>
        </Stack>
        <Button  variant="contained" size="large" onClick={_ => SelectPlan(plan.id)}>Selecionar</Button>
      </Stack>
    </Card>
  );
}
