import { useState, useEffect, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TextField,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Select,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import SendIcon from '@mui/icons-material/Send';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
// import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { EnvelopeListHead, EnvelopeListToolbar } from '../sections/@dashboard/envelope';

import { EntityContext } from '../entity';

import {SignerCard} from '../components/signer-input'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'documentNumber', label: 'Numero de documento', alignRight: false },
  { id: 'role', label: 'Papel', alignRight: false },
  { id: 'required', label: 'Obrigatorio', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Data de criação', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.signer_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const AddSignerCard = (props) => {
  return (
    <Stack justifyContent="space-between" mb={5}>
      <Card>
        <Typography sx={{ m: 2 }} >Novo Assinante</Typography>
        <Stack direction="row" alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
          <TextField
            name="documentNumber"
            label="Numero do Documento"
            value={props.signerDocumentNumber}
            type="tel"
            error={props.documentNumberError}
            helperText={props.documentNumberError ? 'Documento inválido' : ''}
            onChange={props.handleDocumentNumberChange}
            inputProps={{ maxLength: 11 }}
          />
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Papel"
                value={props.roleType}
                onChange={e => props.setRoleType(e.target.value)}
            >
                <MenuItem value={'signer'}>Assinante</MenuItem>
                <MenuItem value={'part'}>Parte</MenuItem>
                <MenuItem value={'witness'}>Testemunha</MenuItem>
            </Select>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
            <Checkbox label="Required" checked={props.required} onChange={_ => props.handleChangeRequired()} />
            <Typography>Obrigatorio</Typography>
          <Button 
          sx={{ m: 2 }} 
          variant="contained" 
          component="label" 
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={_ => props.vinculateSigner()}>
          Adicionar
          </Button>
        </Stack>
      </Card>
    </Stack>
  )
}

export default function EnvelopeGroupEditPage() {

  const { groupKey } = useParams();

  const [groupName, setGroupName] = useState('')
  const [signersMinimum, setSignersMinimum] = useState(0)

  const [signerGroupList, setSignerGroupList] = useState([]);
  const [signerKey, setSignerKey] = useState(null)
  const [editGroup, setEditGroup] = useState(false)

  const [signerDocumentNumber, setSignerDocumentNumber] = useState('')
  const [roleType, setRoleType] = useState('signer');
  const [required, serRequired] = useState(false);

  const [showAddSigner, setShowAddSigner] = useState(false);
  const [newUserDocumentNumber, setNewUserDocumentNumber] = useState('');

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [documentNumberError, setDocumentNumberError] = useState(false)

  const [sendedEmailList, setSendedEmailList] = useState([])

  const { resendEmail, getEnvelopeGroup, getSignerByDocument, vinculateSignerToGroup, editGroupApi } = useContext(EntityContext)

  const handleDocumentNumberChange = (event) => {
    const formattedDocumentNumber = event.target.value.replace(/\D/g, ''); // Remove non-digits
  
    // Ensure length is exactly 11 for valid CPF
    const isValid = formattedDocumentNumber.length === 11;
    setSignerDocumentNumber(formattedDocumentNumber);
    setDocumentNumberError(!isValid);
  };

  const handleChangeRequired = () => {
    serRequired(!required);
  };

  const handleResendEmail = (signerKey) => {
    resendEmail(signerKey)
    .then((data) => {
        
        setSendedEmailList([...sendedEmailList, signerKey])
    })
  }

  useEffect(() => {
    getSignnersList()
    }, [])

    const getSignnersList = useCallback(async () => {

        getEnvelopeGroup(groupKey)
            .then((data) => {
            setSignerGroupList(data.signers)
            setGroupName(data.group_name)
            setSignersMinimum(data.signers_minimum)
            
            })
        
        }, [])

    const vinculateSigner = async () => {

        getSignerByDocument(signerDocumentNumber)
        .then((data) => {
            vinculateSignerToGroup(groupKey, data.signer_key, roleType, required)
            .then((data) => {
                
                getSignnersList()
                setSignerDocumentNumber('')
                serRequired(false)
                setRoleType('signer')
                setShowAddSigner(false)
            })
    })


    
    }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = signerGroupList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleToggleAddUser = () => {
    setShowAddSigner(!showAddSigner)
  }

  const handleEditGroup = () => {
    editGroupApi(groupKey, signersMinimum)
    .then((data) => {
        
        setEditGroup(!editGroup)
        getSignnersList()
    })
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signerGroupList.length) : 0;

  const filteredUsers = applySortFilter(signerGroupList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Grupos </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Grupo: {groupKey}
          </Typography>
        </Stack>

        <Stack justifyContent="space-between" mb={5}>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="space-between"  sx={{ p: 3 }} useFlexGap flexWrap="wrap">
                    <Typography variant="h4" gutterBottom mb={0.2}>
                        Nome:
                    </Typography>
                    <TextField sx={{ m: 2 }}
                    id="outlined-basic" 
                    variant="outlined"
                    disabled={!editGroup}
                    onChange={e => setGroupName(e.target.value)}
                    value={groupName}/>
                    <Typography variant="h4" gutterBottom mb={0.2} >
                        Minimo de Assinantes:
                    </Typography>
                    <TextField sx={{ m: 2 }}
                    id="outlined-basic" 
                    variant="outlined"
                    disabled={!editGroup}
                    onChange={e => setSignersMinimum(e.target.value)}
                    value={signersMinimum}/>
                </Stack>
                <Stack
                      direction="row"
                      p={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                >
                    {/* { editGroup ? 
                    <Button size="large" color="success" variant='contained' onClick={_ => handleEditGroup() } >
                      <DoneIcon /> Concluir
                    </Button> :    
                    <Button size="large" color="primary" variant='contained' onClick={_ => setEditGroup(!editGroup)}>
                      <Iconify icon={'eva:edit-fill'} />Editar
                    </Button> } */}
                </Stack> 
            </Card>
        </Stack>    

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Assinantes
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleToggleAddUser}>
            Adicionar Assinante
          </Button> */}
        </Stack>

        { showAddSigner ? 
            <Stack justifyContent="space-between" mb={5}>
                <AddSignerCard signerDocumentNumber={signerDocumentNumber} 
                handleDocumentNumberChange={handleDocumentNumberChange}
                roleType={roleType} setRoleType={setRoleType} 
                required={required} handleChangeRequired={handleChangeRequired} 
                vinculateSigner={vinculateSigner} documentNumberError={documentNumberError}/>
            </Stack> : <></> }

        <Card>
          <EnvelopeListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnvelopeListHead
                    headLabel={TABLE_HEAD}
                    rowCount={signerGroupList.length}
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                  />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const selectedUser = selected.indexOf(row.user_given_name) !== -1;

                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                        <></>  {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row.user_given_name)} /> */}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {row.signer_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{row.signer_email}</TableCell>

                        <TableCell align="left">{row.signer_document_number}</TableCell>

                        <TableCell align="left">{row.role_type}</TableCell>

                        <TableCell align="left">{row.required ? 
                         <Label>Sim</Label> : <Label>Não</Label>
                        }</TableCell>


                        <TableCell align="left">
                         <Label>{row.signature_status}</Label>
                        </TableCell>

                        <TableCell align="left">{row.created_at.substring(0, 10).concat(' ').concat(row.created_at.substring(11, 19))}</TableCell>

                        {/* Se o envelope_signer_key já estiver na lista apresentar o icone de done
                        caso contrario apresentar o botão de reenviar. */}

                        <TableCell align="right">
                        { sendedEmailList.includes(row.envelope_signer_key) || row.signature_status === 'signed' ?
                        <IconButton size="small" color="inherit" >
                          <DoneIcon />
                        </IconButton> :
                        <IconButton size="small" color="inherit" onClick={_ => handleResendEmail(row.envelope_signer_key)}>
                          <SendIcon />
                        </IconButton>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={signerGroupList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
