import { Helmet } from 'react-helmet-async';

import {
  Stack, 
  Typography,
  Container
} from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';

function SignatureFinishedPage(props) {

  return (
    <>
    
      <Helmet>
          <title> Assinatura Concluida </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack alignItems="center" justifyContent="center" m={12}>
            <DoneAllIcon color="success" sx={{ fontSize: 100 }} />
            <Typography>Assinatura realizada com sucesso!</Typography>
        </Stack>
        </Container>
  </> 
  );
}

export default SignatureFinishedPage;
