import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
// components
import Logo from '../../components/logo';
import Loading from '../../components/loading';
import { EntityContext } from '../../entity';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {

  const { loading, erro, setErro, errorMessage } = useContext(EntityContext)

  return (
    <>
      <StyledHeader>
            { erro ? 
              <Alert severity="warning" onClose={() => setErro(false)}>
                {errorMessage}
              </Alert>
              : <Logo/> }
      </StyledHeader>
      {loading && 
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
        <Loading />
      </div>}

      <Outlet />
    </>
  );
}
