import { useContext } from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
// @mui
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import {EntityContext} from '../../../entity'

// ----------------------------------------------------------------------

EntityCard.propTypes = {
    entity: PropTypes.object,
};

export default function EntityCard({ entity }) {
    const navigate = useNavigate();

    const { setEntity } = useContext(EntityContext)

    const navigateToEntity = (entity) => {
        // 👇️ navigate to /contacts
        setEntity(entity)
        navigate(`/dashboard/app`);
      };

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle2" noWrap>
        {entity.entity_name}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
            {entity.entity_document_number}
          </Typography>
          <Button onClick={_ => navigateToEntity(entity)}>Selecionar</Button>
        </Stack>
      </Stack>
    </Card>
  );
}
