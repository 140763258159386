import React, {useState, useContext, useCallback, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
    Tabs,
    Tab,
    Typography,
    Box,
    Stack,
    Container,
    Grid,
    Card,
    TextField,
    Button
  } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from '@mui/icons-material/Download';
import Label from '../components/label';
import Iconify from '../components/iconify';
import EnvelopeGroupsTable from '../components/envelope-groups-table/index'

import FileInput from '../components/file-input/index'
import CreateEnvelope from '../components/create-envelope/index'
import {GroupList} from '../components/signer-input'

import { EntityContext } from '../entity'

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UpdateEnvelopePage() {

  const { envelopeKey } = useParams();

  const { getEnvelope } = useContext(EntityContext)

  const [value, setValue] = useState(0);
  const [envelopePath, setEnvelopePatth] = useState('');
  const [envelopeStatus, setEnvelopeStatus] = useState('created');
  const [envelopeName, setEnvelopeName] = useState('');
  const [documentsList, setDocumentsList] = useState([]);
  const [signersList, setSignersList] = useState([]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeStep = (newStep) => {
    setValue(newStep)
  }

  const handleDownload = (filename) => {

      const finalFilename = `${filename}.pdf`
      downloadFile(envelopePath, finalFilename)
  }

  const downloadFile = (filePath, fileName) => {
      fetch(filePath, {
        method: 'GET'
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
              'download',
              fileName,
            );

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        });
    };

  const getEnvelopeStatus = useCallback(async () => {
	
    getEnvelope(envelopeKey)
        .then((data) => {
          setEnvelopeStatus(data.envelope.status)
          setEnvelopeName(data.envelope.envelope_name)
          const envPath = (data.envelope.signed_envelope_url ? data.envelope.signed_envelope_url : data.envelope.url)
          setEnvelopePatth(envPath)
          setDocumentsList(data.documents)
          setSignersList(data.groups)
        })
	
	  }, [envelopeKey])

  useEffect(() => {
		getEnvelopeStatus()
	  }, [])

  return (
    <>
    <Helmet>
        <title> Envelopes </title>
    </Helmet>

    { envelopeStatus === 'created' ? 

      <Container>
      <Grid container spacing={10} >
          <Grid item xs={12} sm={6} md={10}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Envelope" {...a11yProps(0)} disabled={false}/>
                  <Tab label="Documentos" {...a11yProps(1)} disabled={false}/>
                  <Tab label="Grupo de Assinantes" {...a11yProps(2)} disabled={false}/>
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <CreateEnvelope changeStep={changeStep} envelopeKey={envelopeKey} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FileInput changeStep={changeStep} envelopeKey={envelopeKey} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <GroupList changeStep={changeStep} envelopeKey={envelopeKey} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Stack alignItems="center" justifyContent="center" m={12}>
                    <DoneAllIcon color="success" sx={{ fontSize: 100 }} />
                    <Typography>Envelope enviado para assinatura!</Typography>
                </Stack>
              </TabPanel>
            </Box>
          </Grid>
      </Grid>
      </Container>

      :
      <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Envelope: {envelopeKey}
          </Typography>
          <Button 
            sx={{ m: 2 }} 
            variant="contained" 
            component="label" 
            startIcon={<DownloadIcon/>}
            onClick={_ => handleDownload('teste')}>
            Baixar
          </Button>
        </Stack>
        <Card >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Informações
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="subtitle2" noWrap>
              Nome: {envelopeName}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Quantidade de documentos: {documentsList.length}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Status: <Label>{envelopeStatus}</Label>
            </Typography>
          </Stack>
        </Card>
      </Container>
        <EnvelopeGroupsTable signersList={signersList}/>
      </>
    }


    </>
  );
}
