import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { EntityContext } from '../../entity';
import Loading from '../../components/loading';

export default function DefaultLayout() {

  const { loading } = useContext(EntityContext)

  return (
    <>
      {loading && 
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
        <Loading />
      </div>}
      <Outlet />
    </>
  );
}
