import PropTypes from 'prop-types';
// @mui
import {Typography, Grid } from '@mui/material';
import EntityCard from './EntityCard';

// ----------------------------------------------------------------------

EntityList.propTypes = {
    entities: PropTypes.array.isRequired,
};

export default function EntityList({ entities, ...other }) {

  return (
    <Grid container spacing={3} {...other}>
      {entities.map((entity) => (
        <Grid key={entity.id} item xs={12} sm={6} md={6}>
          <EntityCard entity={entity} />
        </Grid>
      ))}
    </Grid>
  );
}