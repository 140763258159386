import React, { createContext } from 'react'

import {
	AuthenticationDetails,
	CognitoUser,
    CognitoUserAttribute 
} from 'amazon-cognito-identity-js';

import Pool from './userPool'

const AccountContext = createContext()

const Account = (props) => {

    const getSession = async () =>
    new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                    const results = {}
                    Object.entries(attributes).forEach(([key, value]) => {
                        results[key] = value
                    });
                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes
            });
          }
        });
      } else {
        reject();
      }
    });

    const register = async (documentNumber, email, password, givenName, familyName) => {
        return new Promise((resolve, reject) => {

            const attributeList  = []

            const dataGivenName = {
                Name: 'given_name',
                Value: givenName
            }

            const dataFamilyName = {
                Name: 'family_name',
                Value: familyName
            }

            const dataEmail = {
              Name: 'email',
              Value: email
          }

            const attributeGivenName = new CognitoUserAttribute(dataGivenName);
            const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
            const attributeEmail = new CognitoUserAttribute(dataEmail);
        
            attributeList.push(attributeGivenName);
            attributeList.push(attributeFamilyName);
            attributeList.push(attributeEmail);

            Pool.signUp(documentNumber, password, attributeList, null, (err, data) => {
                if(err){
                    reject(err)
                }
                resolve(data)
            })
        })
    }

    const authenticate = async (Username, Password) => {
        return new Promise((resolve, reject) => {
            const user = new CognitoUser({Username, Pool})
      
            const authDetails = new AuthenticationDetails({Username, Password})
        
            user.authenticateUser(authDetails, {
              onSuccess: (data) => {
                
                resolve(data)
              },
              onFailure: (err) => {
                reject(err)
              },
              newPasswordRequired: (data) => {
                resolve(data)
              },
              UserNotConfirmedException: (data) => {
                resolve(data)
              }
            })
        })
    }

    const verifyAccount = async (Username, OTP) => {
        return new Promise((resolve, reject) => {
        const user = new CognitoUser({Username, Pool})
        user.confirmRegistration(OTP, true, (err, data) => {
          if (err) {
            
            resolve(false)
          } else {
            
            resolve(true)
          }
        });
        })
    };

    const logout = () => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.signOut()
        }
    }

    /* Implementar a função de esqueci a senha */
    const forgotPassword = async (Username) => {
        return new Promise((resolve, reject) => {
            const user = new CognitoUser({Username, Pool})
            user.forgotPassword({
                onSuccess: (data) => {
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                }
            })
        })
    }

    /* Implementar a função de confirmação para o esqueci a senha */
    const confirmForgotPassword = async (Username, code, newPassword) => {
        return new Promise((resolve, reject) => {
            const user = new CognitoUser({Username, Pool})
            user.confirmPassword(code, newPassword, {
                onSuccess: (data) => {
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                }
            })
        })
    }

    return(
        <AccountContext.Provider value={{ authenticate, getSession, logout, register, verifyAccount, forgotPassword, confirmForgotPassword }}>
            {props.children}
        </AccountContext.Provider>
    )

}

export { Account, AccountContext }