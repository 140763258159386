import { useState, useContext, useEffect, useCallback } from 'react';
// @mui
import {
  Stack,
  Typography,
  Divider 
} from '@mui/material';
// components
import Label from '../label'

import { EntityContext } from '../../entity';

export default function InvoicesList() {

  const [checkousList, setCheckoutsList] = useState([])

  const { getinvoices } = useContext(EntityContext)

  useEffect(() => {
    getInvoice()
    }, [])


    const getInvoice = useCallback(async () => {
        getinvoices()
            .then((data) => {
                setCheckoutsList(data)
            })
            }, [])

  return (
    <Stack direction="column" alignItems="stretch" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}
        divider={<Divider orientation="vertical" flexItem style={{width:'100%'}} />}>
        {checkousList.length === 0 ? <></>:
        checkousList.map((checkout) => (
        <Stack direction="row" spacing={2} sx={{ p: 1 }} justifyContent="space-between">
        <Typography variant="subtitle2" noWrap>
            ID: {checkout.id}
        </Typography>
        <Typography variant="subtitle2" noWrap>
            Valor: {checkout.amount}
        </Typography>
        <Typography variant="subtitle2" noWrap>
            Data: {checkout.created_at.substring(0, 10)}
        </Typography>
        <Typography variant="subtitle2" noWrap>
            Status: <Label>{checkout.status}</Label>
        </Typography>
        </Stack>
        ))}
    </Stack>
  );
}
