import PropTypes from 'prop-types';
// @mui
import { Grid, Typography } from '@mui/material';
import TemplateCard from './TemplateCard';

// ----------------------------------------------------------------------

const FinalTemplateList = ({ templatesList, ...other }) => {
    return (
        <Grid container spacing={3} {...other}>
          { templatesList.map((template) => (
            <Grid key={template.id} item xs={12} sm={6} md={6}>
              <TemplateCard template={template} />
            </Grid>
          ))}
        </Grid>
      );
}

export default function TemplatesList({ templatesList, ...other }) {
  return (
    <>
      { templatesList.length === 0 ? 
        <Typography noWrap>Nenhum Template cadastrado</Typography>
       : <FinalTemplateList templatesList={templatesList}/> }
    </>
      )
}
