import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  Stack, 
  Typography,
  TextField,
  Container
} from '@mui/material';

import SignatureButton from '../components/signature-button';

function BatchSignatureConfirmationPage(props) {

    const { batchKey } = useParams();

    const [otp, setOtp] = useState('');

  return (
    <>
    
      <Helmet>
          <title> Confirmar Assinatura </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack alignItems="center" justifyContent="center" m={12} spacing={5}>
            <Typography>Insira o Token de Autenticação:</Typography>
            <TextField value={otp} onChange={e => setOtp(e.target.value)} id="outlined-basic" variant="outlined" inputProps={{ maxLength: 6 }}/>
            <SignatureButton otp={otp} batchKey={batchKey}/>
        </Stack>
        </Container>
  </> 
  );
}

export default BatchSignatureConfirmationPage;
