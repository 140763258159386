import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
	AuthenticationDetails,
	CognitoUser,
} from 'amazon-cognito-identity-js';

// components
import Iconify from '../../../components/iconify';

import { AccountContext } from '../../../account';

// ----------------------------------------------------------------------

export default function AccountConfirmationForm(props) {

  const [token, setToken] = useState("")

  return (
    <>
      <Stack spacing={3}>
      <TextField name="token" label="Token" value={token}
        onChange={e => setToken(e.target.value)}/>
      </Stack>

      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={_ => props.verify(token)}>
        Confirmar Registro
      </LoadingButton>
    </>
  );
}
