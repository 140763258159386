import { useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Alert, IconButton, Collapse, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm, SignInForm, ChangePasswordForm, AccountConfirmationForm } from '../sections/auth/login';

import { AccountContext } from '../account';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ErrorAlert = (props) => {
  return (
    <Alert severity="error" 
    action={
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => {
          props.setOpen(false);
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    }
      sx={{ mb: 2 }}
    >
    <AlertTitle>Error</AlertTitle>
    {props.message}
    </Alert>
  )
}

// ----------------------------------------------------------------------

export default function LoginPage() {

  const platformName = 'Chancela Fácil'

  const mdUp = useResponsive('up', 'md');

  // status = 'register', 'login', 'loged', 'resetPassord', 'accountConfirmation'
  const [status, setStatus] = useState('login')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { authenticate, verifyAccount, forgotPassword, confirmForgotPassword } = useContext(AccountContext)

  const HandleSuport = () => {
    const whatsappURL = `https://wa.me/5511985441304?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20para%20acessar%20minha%20conta.`;
    window.open(whatsappURL, '_blank');  
};

  const signUp = (email, password) => {
      authenticate(email, password)
      .then(data => {
        window.location.reload();
      })
      .catch(err => {
        
        if(err.name === 'UserNotConfirmedException'){
          setUserName(email)
          setPassword(password)
          setStatus('accountConfirmation')
        } else if(err.name === 'InvalidParameterException'){
          setOpen(true)
          setErrorMessage('Por favor, insira dados validos.')
        } else if(err.name === 'NotAuthorizedException'){
          setOpen(true)
          setErrorMessage('Usuario ou senha incorretos.')
        }
        
      }
      )
    
  };

  const tryForgotPassword = (documentNumber) => {
    forgotPassword(documentNumber)
    .then(data => {
      setUserName(documentNumber)
      setStatus('resetPassord')
      }
    ).catch(err => {
      
      if(err.name === 'LimitExceededException'){
        setOpen(true)
        setErrorMessage('Limite de tentativas excedido, tente novamente mais tarde.')
      }
    }
    )
  };
  
  const verify = async (OTP) => {

    const done = await verifyAccount(userName, OTP)

    if(done){
      await signUp(userName, password)
    }
    
  };

  const veryfyForgotPassword = async (OTP, newPassword) => {
    const done = await confirmForgotPassword(userName, OTP, newPassword)

    if(done){
      await signUp(userName, newPassword)
    }
  };

  const HandleUserStatus = () => {

    switch(status) {
      case 'login':
        return (
          <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Entrar em {platformName}
            </Typography>

            <Typography variant="body2" sx={{ mb: 3 }}>
            Não tem uma conta? {''}
            <Link variant="subtitle2" onClick={_ => setStatus('register')}>Cadastre-se</Link>
            </Typography>

            { open ? <ErrorAlert setOpen={setOpen} message={errorMessage} /> : <></> }

            <LoginForm signUp={signUp} setStatus={setStatus} tryForgotPassword={tryForgotPassword}/>

          </StyledContent>
        </Container>
        )
      case 'register':
        return (
          <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Registrar-se em {platformName}
            </Typography>

            <Typography variant="body2" sx={{ mb: 3 }}>
            Já tem uma conta? {''}
            <Link variant="subtitle2" onClick={_ => setStatus('login')}>Entrar</Link>
            </Typography>

            { open ? <ErrorAlert setOpen={setOpen} /> : <></> }

            <SignInForm setStatus={setStatus}/>

          </StyledContent>
        </Container>
        )
        case 'resetPassord':
          return (
            <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Não consegue acessar sua conta?
              </Typography>

              { open ? <ErrorAlert setOpen={setOpen} /> : <></> }
    
              <ChangePasswordForm veryfyForgotPassword={veryfyForgotPassword}/>
              {/* texto "Clique aqui para entrar em contato com nosso suporte", onde no "clique aqui" é um link para o whatsapp do suporte */}
              {/* <Typography variant="body2" sx={{ mb: 3 }}>
                <Link variant="subtitle2" onClick={_ => HandleSuport()}>Clique aqui</Link>
                {''} para entrar em contato com nosso suporte.
              </Typography> */}

              
            </StyledContent>
          </Container>
          )
          case 'accountConfirmation':
            return (
              <Container maxWidth="sm">
              <StyledContent>
                <Typography variant="h4" gutterBottom>
                  Confirmar conta
                </Typography>
                <Typography variant="body2" sx={{ mb: 3 }}>
                Enviamos um token de acesso para o seu email
                </Typography>

                { open ? <ErrorAlert setOpen={setOpen} /> : <></> }
      
                <AccountConfirmationForm verify={verify}/>
    
              </StyledContent>
            </Container>
            )
      default:
        return <></>
    }

  }

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Oi, Bem vindo de volta
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <HandleUserStatus />
      </StyledRoot>
    </>
  );
}
