import { useState, useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
//
import Header from './header';
import Nav from './nav';
import Loading from '../../components/loading';

import { EntityContext } from '../../entity';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const { loading, erro, setErro, errorMessage } = useContext(EntityContext)

  return (
      <StyledRoot>
        <Header onOpenNav={() => setOpen(true)} erro={erro} setErro={setErro} errorMessage={errorMessage} />

        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main>
        {loading && 
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
            <Loading />
          </div>}
          <Outlet />
        </Main>
      </StyledRoot>
  );
}
