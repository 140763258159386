import { useState, useEffect, useContext, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
// components
import Iconify from '../components/iconify';

import PlansList from '../sections/@dashboard/plans/PlansList';

import { EntityContext } from '../entity';
import { AccountContext } from '../account';

// ----------------------------------------------------------------------

export default function PlansPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [token, setToken] = useState(null)
  const [plans, setPlans] = useState(null)

  const { getPlan, setEntityPlan } = useContext(EntityContext)
  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
        .then((data) => {
            setToken(data.idToken.jwtToken)
        })
    }, [])

  useEffect(() => {
    GetPlan()
    }, [token])

    const GetPlan = useCallback(async () => {

      handleSetPlans(token)

    }, [token])

  const handleSetPlans = async (token) => {
    getPlan(token)
      .then(data => {
        setPlans(data)
      })
  }

  const SelectPlan = (planKey) => {
    setEntityPlan(token, planKey)
    .then(data => {
      const checkout = data.checkout.checkout_url
      window.location.replace(checkout);
    })
    
  };

  return (
    <>
      <Helmet>
        <title> Planos </title>
      </Helmet>

      <Container maxWidth="xl">

      {/* Faz um header contendo o titulo da pagina "Planos" */ }
      <Grid container spacing={5} justifyContent="center" sx={{ mt: 10 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h3" align="center" paragraph>
            Planos
          </Typography>
        </Grid>
      </Grid>
      
      { plans ? <PlansList plans={plans} SelectPlan={SelectPlan}/> : <></> }
      
      
      </Container>
    </>
  );
}
