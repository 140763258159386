import React, {useState, useEffect, useCallback, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';

import { camelCase } from 'lodash'

// components
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
// sections
import { EnvelopeListHead, EnvelopeListToolbar } from '../../sections/@dashboard/envelope/index';

import {EntityContext} from '../../entity'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];


export default function FileInput(props) {

    const { getEnvelope, createDocument, vinculateDocumentToEnvelope, removeDocumentToEnvelope } = useContext(EntityContext)

    const [page, setPage] = useState(0);

    const [selected, setSelected] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(5);

	const [fileList, setFilelist] = useState([]);
    const [base64List, setBase64list] = useState([]);

	const envelopeKey = props.envelopeKey

	const camelizeKeys = (obj) => {

		let finalResponse = obj

		if (Array.isArray(obj)) {
			finalResponse = obj.map(v => camelizeKeys(v));
		} else if (obj != null && obj.constructor === Object) {
			finalResponse = Object.keys(obj).reduce(
			(result, key) => ({
			  ...result,
			  [camelCase(key)]: camelizeKeys(obj[key]),
			}),
			{},
		  );
		}
		return finalResponse;
	  };
	
	const getDocuments = useCallback(async () => {
	
    getEnvelope(envelopeKey)
        .then((data) => {
          
          setFilelist(data.documents)
        })
	
	  }, [envelopeKey])



    function getExtension(filename) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }

    function isPdf(filename) {
        const ext = getExtension(filename);

        switch (ext.toLowerCase()) {
            case 'pdf':
                return true
            default:
                return false
        }

      }

    const changeHandler = async (event) => {

      const newDoc = event.target.files[0]

      const validFile = isPdf(newDoc.name) 

      if (validFile) {

        const base64Doc = await convertBase64(newDoc)

        const validBase64Doc = base64Doc.split(',')
        

        const newDocumentRecord = await createDocument(newDoc.name, validBase64Doc[validBase64Doc.length - 1])

        if(newDocumentRecord) {

          const addedToEnvelope = await vinculateDocumentToEnvelope(newDocumentRecord.documentKey, envelopeKey)

          if(addedToEnvelope) {
            getDocuments()
          }

        }
      }

    };

    const handleSubmission = async () => {

		if (fileList.length === 0) {
			
			props.changeStep(1)

		} else {
	
			props.changeStep(2)

		}

    };

	useEffect(() => {
		getDocuments()
	  }, [])

      const removeFileFromEnvelope = async (documentKey) => {
        removeDocumentToEnvelope(documentKey, props.envelopeKey)
        .then((response) => {
            getDocuments()
        })
      }

    const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onError = (error) => {
                reject(error)
            }

        })
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = fileList.map((n) => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fileList.length) : 0;

    return (
        <>
        <Helmet>
            <title> User </title>
        </Helmet>

        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                Adicionar Documento
                <input 
                    hidden accept="application/pdf" 
                    multiple 
                    type="file" 
                    name="file"
                    onChange={changeHandler}/>
                </Button>
                <Button variant="contained"
                onClick={handleSubmission}>
                    Adicionar Signatarios
                </Button>
            </Stack>

            <Card>
            <EnvelopeListToolbar numSelected={selected.length} />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <EnvelopeListHead
                    headLabel={TABLE_HEAD}
                    rowCount={fileList.length}
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                    {fileList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            
                        const selectedUser = selected.indexOf(row.document_name) !== -1;

                        return (
                        <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row.document_name)} />
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                {row.document_name}
                                </Typography>
                            </Stack>
                            </TableCell>

                            <TableCell align="right">
                            <IconButton size="large" color="error" onClick={_ => removeFileFromEnvelope(row.document_key)}>
                                <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>

                </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={fileList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Card>


        </Container>
        </>
    );
}
