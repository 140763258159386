import { Helmet } from 'react-helmet-async';
import { useState, useContext, useEffect } from 'react';
// @mui
import {
  TextField,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
} from '@mui/material';
// components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../components/iconify';
import Label from '../components/label';

import { AccountContext } from '../account';

export default function ProfilePage() {

  const [userName, setUserName] = useState('');
  const [userFamilyName, setUserFamilyName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userDocumentNumber, setUserDocumentNumber] = useState('');


  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
        .then((data) => {
          setUserName(data[2].Value)
          setUserEmail(data[4].Value)
          setUserFamilyName(data[3].Value)
          setUserDocumentNumber(data.user.username)
        })
}, [])

  return (
    <>
      <Helmet>
        <title> Perfil </title>
      </Helmet>

      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Perfil
          </Typography>
        </Stack>

        <Card >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Informações
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="subtitle2" noWrap>
              Nome: {userName}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Sobrenome: {userFamilyName}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Email: {userEmail}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              CPF: {userDocumentNumber}
            </Typography>
          </Stack>
        </Card>
      </Container>
    </>
  );
}
